<div class="date-time">
  <mat-form-field [appearance]="apperance" class="date">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
      #dateInput
      matInput
      [matDatepicker]="datePicker"
      name="date"
      [formControl]="control"
      [min]="min"
      [max]="max"
    />
    <mat-datepicker-toggle *ngIf="!disabled" matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker touchUi></mat-datepicker>
    <mat-hint *ngIf="optional"><i>Optional</i></mat-hint>
    <mat-error *ngIf="error">{{ error }}</mat-error>
  </mat-form-field>
  <mat-divider [vertical]="true"></mat-divider>
  <mat-form-field [appearance]="apperance" class="time">
    <input
      matInput
      [format]="24"
      [ngxMatTimepicker]="timePicker"
      [ngModel]="_time"
      (ngModelChange)="time = $event"
      name="time"
      readonly
      [disabled]="disabled"
      [errorStateMatcher]="timeErrorMatcher"
      [min]="timeMin"
      [max]="timeMax"
    />
    <ngx-mat-timepicker #timePicker></ngx-mat-timepicker>
    <mat-icon *ngIf="!disabled" matSuffix (click)="timePicker.open()" class="time-icon">watch_later</mat-icon>
  </mat-form-field>
</div>
