import { createSelector } from '@ngrx/store';
import { State } from './user.state';

const selectUserState = (rootState): State => rootState.user;

export const selectMe = createSelector(selectUserState, (state: State) => state.me);
export const selectSubscription = createSelector(selectUserState, (state: State) => state.sub);
export const selectSubscriptionIsLoaded = createSelector(selectUserState, (state: State) => state.subLoaded);
export const selectTeam = createSelector(selectUserState, (state: State) => state.ranchTeam);

export const selectMaxTeamMembers = createSelector(selectSubscription, (sub) => sub?.max_team_members);
export const selectRanchMembersCount = createSelector(
  selectTeam,
  (team) => team.filter((t) => t.role === `member`).length
);
