import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../alert/alert.service';
import { AUTH_TOKEN, ADMIN_TOKEN } from '../consts/cookie-names.const';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private alertService: AlertService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.getToken();
    const request = token ? this.decorateRequest(req, token) : req;

    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => this.handleErrorResponse(error)));
  }

  private getToken(): string {
    const currentUrl = window.location.href;

    if (this.isAdminUser() && this.requestRequiresAdminToken(currentUrl)) {
      return this.getAdminUserToken();
    }

    if (this.requestRequiresMasqueradeToken(currentUrl)) {
      return this.cookieService.get(ADMIN_TOKEN);
    }

    return this.cookieService.get(AUTH_TOKEN);
  }

  private isAdminUser(): boolean {
    const adminUser = JSON.parse(sessionStorage.getItem(`currentAdmin`) || `{}`);
    return adminUser.is_admin;
  }

  private getAdminUserToken(): string {
    const adminUser = JSON.parse(sessionStorage.getItem(`currentAdmin`) || `{}`);
    return adminUser.token;
  }

  private requestRequiresAdminToken(url: string): boolean {
    return /(panel|kml)/gi.test(url);
  }

  private requestRequiresMasqueradeToken(url: string): boolean {
    return /(export)/gi.test(url);
  }

  private decorateRequest(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Token ${token}`,
      },
    });
  }

  private handleErrorResponse(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
      this.goLogin();
    } else {
      this.showErrorAlert(error);
    }
    return throwError(() => error);
  }

  private showErrorAlert(error: HttpErrorResponse): void {
    let reason: string;
    if (error.error) {
      if (Array.isArray(error.error.details)) {
        reason = error.error.details[0];
      } else if (typeof error.error.details === `object`) {
        reason = Object.values(error.error.details)[0] as string;
      } else {
        reason = error.error.details ?? error.error;
      }
    } else {
      reason = error.statusText;
    }
    this.alertService.showError(reason || `An error occurred`, `Error`);
  }

  private goLogin(): void {
    const returnUrl = this.router.routerState.snapshot.url;
    this.router.navigate([`/login`], { queryParams: { returnUrl } });
  }
}
