import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, switchMap } from 'rxjs';

import { SettingsUserService } from 'src/app/settings/settings-user.service';
import { Animal } from 'src/app/shared/models/animal.model';
import { Herd, HerdWithHistory } from 'src/app/shared/models/herd.interface';
import { Infrastructure } from 'src/app/shared/models/inventory.interface';
import { Paddock } from 'src/app/shared/models/paddock.interface';
import { RainfallEvent } from 'src/app/shared/models/rainfall-event.interface';
import { RainfallStation } from 'src/app/shared/models/rainfall-station.interface';
import { Ranch } from 'src/app/shared/models/ranch.interface';
import { Season } from 'src/app/shared/models/season.interface';
import { SettingsService } from 'src/app/shared/settings/settings.service';

@Injectable({
  providedIn: `root`,
})
export class RanchService {
  constructor(
    private readonly http: HttpClient,
    private readonly settings: SettingsService,
    private readonly sus: SettingsUserService
  ) {}

  public getRanch(): Observable<Ranch> {
    return this.http.get<Ranch>(`${this.settings.settings.API_V3}/ranch/${this.sus.ranchUUID}`);
  }

  public getHerds(): Observable<Array<Herd>> {
    return this.http.get<Array<Herd>>(
      `${this.settings.settings.API_V3}/ranch/${this.sus.ranchUUID}/herd?include_terminal_herds=true&include_archived_herds=true`
    );
  }

  public getHerd(id: string): Observable<Herd> {
    return this.http.get<Herd>(`${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}/herd/${id}`);
  }

  public getPaddocks(): Observable<Array<Paddock>> {
    return this.http.get<Array<Paddock>>(
      `${this.settings.settings.API_V3}/ranch/${this.sus.ranchUUID}/season/${this.sus.seasonUUID}/paddock?include_uncompleted_tasks=true`
    );
  }

  public getSeasons(): Observable<Array<Season>> {
    return this.http.get<Array<Season>>(`${this.settings.settings.API_V3}/ranch/${this.sus.ranchUUID}/season`);
  }

  public getInfrastructures(): Observable<Array<Infrastructure>> {
    return this.http.get<Array<Infrastructure>>(
      `${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}/season/${this.sus.seasonUUID}/object`
    );
  }

  public getRainfall(): Observable<Array<RainfallEvent>> {
    return this.http.get<Array<RainfallEvent>>(
      `${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}/rainfall/data`
    );
  }

  public getRainfallStations(): Observable<Array<RainfallStation>> {
    return this.http.get<Array<RainfallStation>>(
      `${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}/rainfall/set-source`
    );
  }

  public changeRainfallStartDate(startDate: string): Observable<Ranch> {
    return this.http.patch<Ranch>(`${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}`, {
      rainfall_start_date: startDate,
    });
  }

  public changeRainfallStation(station_id: string, station_name: string): Observable<Array<RainfallEvent>> {
    return this.http.post<Array<RainfallEvent>>(
      `${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}/rainfall/set-source`,
      {
        station_id,
        station_name,
      }
    );
  }

  public createRainfallEvent(date: string, value: number): Observable<void> {
    return this.http.post<void>(`${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}/rainfall/data/create`, {
      date,
      value,
      ranch_uuid: this.sus.ranchUUID,
    });
  }

  public uploadRainfallCsv(file: any): Observable<Array<RainfallEvent>> {
    return this.http.post<Array<RainfallEvent>>(
      `${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}/rainfall/data/csv`,
      {
        csvTexts: file,
      }
    );
  }

  public getHerdHistory(id: string): Observable<HerdWithHistory> {
    return this.http.get<HerdWithHistory>(
      `${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}/herd/${id}/?include_terminal_herds=true&include_archived_herds=true&include_history_for_season=${this.sus.seasonUUID}`
    );
  }

  public getHerdAnimals(id: string): Observable<Array<Animal>> {
    return this.fetchAnimals(id);
  }

  private fetchAnimals(id: string, requestUrl?: string): Observable<Array<Animal>> {
    const url =
      requestUrl ??
      `${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}/herd/${id}/animal/v2?include_animalrecords=true`;

    return this.http.get<any>(url).pipe(
      switchMap((response) => {
        const nextUrl = response?.next;
        const currentAnimals: Array<Animal> = response?.results ?? [];

        return nextUrl
          ? this.fetchAnimals(id, nextUrl).pipe(map((nextAnimals) => currentAnimals.concat(nextAnimals)))
          : of(currentAnimals);
      })
    );
  }

  public getTerminalHerdAnimals(id: string): Observable<Array<Animal>> {
    return this.http.get<Array<Animal>>(
      `${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}/herd/${id}/culled/?include_animalrecords=true`
    );
  }
}
